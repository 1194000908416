<template>
    <div>
        <kendo-grid ref="grid"
                    :data-source="dataSource"
                    :pageable="pageableConfig"
                    :filterable="filterableConfig"
                    :sortable="true"
                    :columns="columns"
                    :resizable="true"
        >
        </kendo-grid>
    </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import praProjectService from '../Script/PraProjectServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PraProjectGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['editClick', 'deleteClick', 'statusClick', 'commissionClick'],
    data(){
        return{
            dataSource: new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging
                            }
                            return { 
                                query: praProjectService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetTransactionPreProject.pre_project == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionPreProject.pre_project;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetTransactionPreProject.pre_project == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionPreProject.total;
                        }
                    },
                    model: {
                        fields: {
                            pre_project_date: {type: "date"},
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            }),
            columns:  [
                { title: "Action", width: 210, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" },
                    template: this.columnButton
                },
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "pre_project_code", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "pre_project_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "pre_project_date", title: "Tanggal Pra Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(pre_project_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "sales_name", title: "Sales", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "areaname", title: "Area", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "notes", title: "Catatan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        }
    },
    mounted: function(){
        var deleteClick = this.$props.deleteClick;
        var editClick = this.$props.editClick;
        var statusClick = this.$props.statusClick;
        var commissionClick = this.$props.commissionClick;

        var grid = this.$refs.grid.kendoWidget();
        var GridElement = grid.element;
        
        GridElement.on("click", "#EditButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, false);
        })

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            editClick(dataItem, true);
        })

        GridElement.on("click", "#DeleteButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
           
            deleteClick(dataItem.pre_project_id);
        })

        ////Status Form
        GridElement.on("click", "#StatusButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            statusClick(dataItem);
        })

        ////Commission
        GridElement.on("click", "#CommissionButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            commissionClick(dataItem.pre_project_id);
        })
    },
    methods: {
        columnButton(e){
            var customButton = "";
            if (e.status == "Komisi") {
                customButton = `&nbsp;&nbsp;
                                <button type="button" class="btn btn-secondary btn-sm rounded" id="CommissionButton">
                                    <i class="fa fa-plus"></i> </span>
                                </button>`;
            };

            return this.$globalfunc.gridActionWithStatusChangesButton("Pra Project", customButton)
        },
        changeStatus(status){
            this.dataSource= new kendo.data.DataSource({
                page: 1,
                pageSize: kendo_grid.default_grid_pagesize,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                status : status
                            }
                            return { 
                                query: praProjectService.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function(response) {
                        if(response.data.GetTransactionPreProject.pre_project == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionPreProject.pre_project;
                        }
                    },
                    total: function(response) {
                        if(response.data.GetTransactionPreProject.pre_project == null){
                            return [];
                        }
                        else{
                            return response.data.GetTransactionPreProject.total;
                        }
                    },
                    model: {
                        fields: {
                            pre_project_date: {type: "date"},
                            created_at: {type: "date"},
                        }
                    }
                },
                sort: { field: "created_at", dir: "desc" }
            });
        },
    }
}
</script>

<style scoped>
</style>