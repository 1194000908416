import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class PRMService {
    readPRMQuery(){
        var query = `
            query ($paging:ServerPaging,   $projecttype:Int, $prm:Boolean) {
                GetTransactionProject (Paging:$paging, ProjectType:$projecttype, PRM:$prm) {
                    project {
                        status
                        project_code
                        project_name
                        notes
                    }
                    total
                }
            }
        `;
        return query;
    }
}

export default new PRMService();