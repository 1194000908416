<template>
    <div>
        <div class="modal fade" id="PraProjectStatusModal" tabindex="-1" aria-labelledby="PraProjectStatusModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="PraProjectStatusModalLabel" class="font-weight-bold">{{ModalTitle}}{{PraProjectCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <div class="p-3">
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                        </div>
                        
                        <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;"> Save </CButton>
                        <label style="float: right;">&nbsp;&nbsp;</label>
                        <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close"> Close </CButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import {globalfunc} from '../../../../shared/GlobalFunction';
import praProjectService from '../Script/PraProjectServices.js';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'PraProjectStatusForm',
    components: {
    },
    props: ['reload'],
    data: function () {
        return {
            ModalTitle : '',

            Id : '',
            PraProjectCode : '',
            Status : '',
            StatusData : [],
        }
    },
    methods: {
        async statusClick(data){
            this.ModalTitle = 'Edit Status Pra Project: ';

            this.Id = data.pre_project_id;
            this.PraProjectCode = data.pre_project_code;
            this.StatusData = await globalfunc.globalDropdown('ddl_status_preproject');
            this.Status = data.status;
            
            window.$('#PraProjectStatusModal').modal('show');
        },
        async saveClick(){
            var data = await praProjectService.getDetailPraProject(this.Id);

            const preProjectData = {
                pre_project_code: data.pre_project_code,
                pre_project_date: data.pre_project_date,
                pre_project_name: data.pre_project_name,
                status: this.Status,
                sales_id: data.sales_id,
                areacode: data.areacode,
                notes: data.notes
            };

            const variables = {
                id : this.Id,
                data : preProjectData
            };
            
            praProjectService.editQuery(variables).then(res => {
                this.$loading(false);
                window.$('#PraProjectStatusModal').modal('hide');
                this.$swal("Info", response.successUpdate, "success");
                this.$props.reload();
            }).catch(e => {
                this.$loading(false);
                var errorMessage = this.$globalfunc.ErrorExtractor(e)
                this.$swal("Error", errorMessage, "error");
            })
        },
    }
}
</script>

<style scoped>
.modal-xl-custom {
    max-width: 1500px;
}
</style>