<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true"
            >
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import prmServices from '../Script/PRMServices.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PRMGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                    page: 1,
                    pageSize: kendo_grid.default_grid_pagesize,
                    serverPaging: true,
                    serverFiltering: true,
                    serverSorting: true,
                    transport: {
                        read: {
                            contentType: "application/json",
                            url: apiBaseUrl,
                            type: "POST",
                            data: function(e) {
                                const paging = {
                                    Page : {Limit: e.pageSize,Page: e.page},
                                    Filter : globalfunc.filterQueryBuilder(e.filter),
                                    OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                                }
                                const variables = {
                                    paging : paging,
                                    projecttype : 1,
                                    prm : true
                                }
                                return { query: prmServices.readPRMQuery(),
                                         variables : variables}
                            },
                            beforeSend: function (req) {
                                req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                                req.setRequestHeader('Path', window.location.pathname);
                            }
                        },
                        parameterMap: function(options, operation) {
                            return  kendo.stringify({
                                query: options.query,
                                variables: options.variables
                            });
                        },
                        cache: false,
                    },
                    schema: {
                        data: function (response) {
                            if(response.data.GetTransactionProject.project == null){
                                return [];
                            }else{
                                return response.data.GetTransactionProject.project;
                            }
                        },
                        total: function (response) {
                            if(response.data.GetTransactionProject.project == null){
                                return 0;
                            }else{
                                return response.data.GetTransactionProject.total;
                            }
                        },
                        model: {
                            fields: {
                                start_date: { type: "date"},
                            }
                        }
                    }
                }),
            columns:  [
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"} }, 
                { field: "project_code", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "project_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } }, 
                { field: "notes", title: "Keterangan", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config
        };
    }
}
</script>