<template>
  <div>
        <kendo-grid
            ref="grid" 
            :data-source="dataSource"
            :filterable="filterableConfig"
            :sortable="true"
            :pageable="pageableConfig"
            :columns="columns"
            :resizable="true">
        </kendo-grid>
  </div>
</template>


<script>
import { Grid, GridColumn } from '@progress/kendo-grid-vue-wrapper';
import {globalfunc} from '../../../../shared/GlobalFunction';
import praProjectApproverServices from '../Script/PraProjectApproverScript.js';
import { apiBaseUrl } from '../../../../infrastructure/constant/connection';
import { kendo_grid } from '../../../../infrastructure/constant/variable';

export default {
    name: 'PraProjectApproverGrid',
    components: {
        'kendo-grid' : Grid,
        'kendo-grid-column': GridColumn,
    },
    props: ['viewClick', 'approveClick'],
    data: function () {
        return  {
            dataSource:  new kendo.data.DataSource ({
                page: 1,
                pageSize: 50,
                serverPaging: true,
                serverFiltering: true,
                serverSorting: true,
                transport: {
                    read: {
                        contentType: "application/json",
                        url: apiBaseUrl,
                        type: "POST",
                        data: function(e) {
                            const paging = {
                                Page : {Limit: e.pageSize,Page: e.page},
                                Filter : globalfunc.filterQueryBuilder(e.filter),
                                OrderBy : globalfunc.sortingQueryBuilder(e.sort)
                            }
                            const variables = {
                                paging : paging,
                                approval : true
                            }
                            return { 
                                query : praProjectApproverServices.readQuery(),
                                variables : variables
                            }
                        },
                        beforeSend: function (req) {
                            req.setRequestHeader('Authorization', window.$cookies.get('Token'));
                            req.setRequestHeader('Path', window.location.pathname);
                        }
                    },
                    parameterMap: function(options, operation) {
                        return  kendo.stringify({
                            query: options.query,
                            variables: options.variables
                        });
                    },
                    cache: false,
                },
                schema: {
                    data: function (response) {
                        if(response.data.GetTransactionPreProject.pre_project == null){
                            return [];
                        }else{
                            return response.data.GetTransactionPreProject.pre_project;
                        }
                    },
                    total: function (response) {
                        if(response.data.GetTransactionPreProject.pre_project == null){
                            return 0;
                        }else{
                            return response.data.GetTransactionPreProject.total;
                        }
                    },
                    model: {
                        fields: {
                            pre_project_date: {type: "date"},
                            created_at: {type: "date"},
                        }
                    }
                }
            }),
            columns:  [
                { title: "Action", width: 100, attributes: { style: "text-align:center;" }, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, template:this.columnButton},
                { field: "status", title: "Status", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "pre_project_code", title: "Kode", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "pre_project_name", title: "Nama", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "pre_project_date", title: "Tanggal Pra Project", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, attributes:{style: "text-align:center;"}, 
                    template: "#= kendo.toString(kendo.parseDate(pre_project_date, 'yyyy-MM-dd'), 'yyyy-MM-dd') #" },
                { field: "sales_name", title: "Sales", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "areaname", title: "Area", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" } },
                { field: "valuation", title: "Valuasi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}" },
                { field: "recommended_percentage", title: "Persentase Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}" },
                { field: "recommended_amount", title: "Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}" },
                { field: "commission_amount", title: "Total Komisi", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, format: "{0:N0}" },
                { field: "created_at", title: "Tanggal Dibuat", width: 200, headerAttributes:{ style: "text-align:center; font-weight:bold;" }, 
                    template: "#= kendo.toString(kendo.parseDate(new Date(created_at).toISOString(), 'yyyy-MM-dd'), 'yyyy-MM-dd') #"
                },
            ],
            filterableConfig:  {
                extra: true,
                operators: kendo_grid.default_operator
            },
            pageableConfig: kendo_grid.default_pageable_config,
        };
    },
    mounted: async function() {
        var grid = this.$refs.grid.kendoWidget();
        var approveClick = this.$props.approveClick;
        var viewClick = this.$props.viewClick;
       
        var GridElement = grid.element;

        GridElement.on("click", "#ApproveButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));

            approveClick(dataItem.pre_project_id);
        });

        GridElement.on("click", "#ViewButton", function (e){
            e.preventDefault();
            var dataItem = grid.dataItem(e.target.closest("tr"));
            
            viewClick(dataItem.pre_project_id, true);
        });

    },
    methods: {
        columnButton(){
            return this.$globalfunc.gridActionApproveButton("Pra Project Approver")
        },
    }
}
</script>