<template>
    <div>
        <div class="modal fade" id="praProjectModal" tabindex="-1" aria-labelledby="praProjectModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-md modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h4 id="praProjectModalLabel">{{ModalTitle}}{{PraProjectCode}}</h4>
                        <a type="button" data-bs-dismiss="modal" aria-label="Close">
                            <i class="fa fa-times"></i>
                        </a>
                    </div>

                    <div class="modal-body">
                        <CCardBody>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Kode</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="PraProjectCode" v-model="PraProjectCode" class="font-weight-bold"/>
                                    <label id="errorPraProjectCode" class="form-error" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Nama</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CInput id="PraProjectName" v-model="PraProjectName" class="font-weight-bold" />
                                    <label id="errorPraProjectName" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow class="pb-3">
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Tanggal</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <date-picker
                                        v-model="PraProjectDate"
                                        format="DD MMM YYYY"
                                        type="date"
                                        placeholder="Select date"
                                        value-type="YYYY-MM-DD"
                                        style="width:100%"
                                    ></date-picker>
                                    <label id="errorPraProjectDate" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Status</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <CSelect id="Status" class="font-weight-bold" :options="StatusData" :value.sync="Status" />
                                    <label id="errorStatus" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Sales</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <v-select id="Sales" class="pb-3" :options="SalesData" v-model="Sales"/>
                                    <label id="errorSales" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold required">Area</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol>
                                <CCol sm="8">
                                    <v-select id="Area" class="pb-3" :options="AreaData" v-model="Area"/>
                                    <label id="errorArea" class="form-error" style="display: none; color: red;"></label>
                                </CCol>                                    
                            </CRow>
                            <CRow>
                                <CCol sm="4">
                                    <label class="form-label font-weight-bold">Catatan</label>
                                    <label class="form-label font-weight-bold" style="float:right">:&nbsp;</label>
                                </CCol> 
                                <CCol sm="8" class="pb-3">
                                    <textarea class="form-control" rows="2" v-model="Note"></textarea>
                                    <label id="errorNote" class="form-error pt-3" style="display: none; color: red;"></label>
                                </CCol>
                            </CRow>
                            <br>
                            <CButton type="button" @click="saveClick()" class="save-button" id="saveButton" color="success" style="float: right;">Save</CButton>
                            <label style="float: right;">&nbsp;&nbsp;</label>
                            <CButton type="button" color="secondary" style="float: right;" data-bs-dismiss="modal" aria-label="Close">Close</CButton>
                        </CCardBody> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import praProjectService from '../Script/PraProjectServices.js';
import datePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import moment from 'moment';
import { response } from '../../../../infrastructure/constant/response';

export default {
    name: 'MachineForm',
    props: ['reload'],
    components: {
        'date-picker' : datePicker,
    },
    data: function () {
        return {
            ModalTitle : '',

            Id: '',
            PraProjectCode : '',
            PraProjectName : '',
            PraProjectDate : '',
            StatusData : [],
            Status : '',
            Sales : '',
            SalesData : [],
            Area : '',
            AreaData : [],
            Note : '',

            Error : 0,
            SaveType : '',
        }
    },
    mounted(){

    },
    methods: {
        async addClick(){
            $(".form-error").css("display", "none");
            this.ModalTitle = 'Add Pra-Project: ';

            this.PraProjectCode = 'Auto';
            this.PraProjectName = '';
            var dateNow = moment(new Date()).format("YYYY-MM-DD");
            this.PraProjectDate = dateNow;
            this.StatusData = [ {value: 'New', label: 'New'} ];
            this.Status = 'New';
            this.SalesData = await praProjectService.getSalesQuery();
            this.Sales = '';
            this.AreaData = await praProjectService.getAreaQuery();
            this.Area = '';
            this.Note = '';

            this.SaveType = 'Add'; 
            document.getElementById('PraProjectCode').readOnly = false;
            
            window.$('#praProjectModal').modal('show');
        },
        async editClick(data, view){
            $(".form-error").css("display", "none");
            $(".save-button").show();
            this.ModalTitle = 'Edit Pra-Project: ';

            this.Id = data.pre_project_id;
            this.PraProjectCode = data.pre_project_code;
            this.PraProjectName = data.pre_project_name;
            this.PraProjectDate = data.pre_project_date;
            this.StatusData = await this.$globalfunc.globalDropdown('ddl_status_preproject');
            this.Status = data.status;
            this.SalesData = await praProjectService.getSalesQuery();
            this.Sales = this.SalesData.find(c => c.value == data.sales_id);
            this.AreaData = await praProjectService.getAreaQuery();
            this.Area = this.AreaData.find(c => c.value == data.areacode);
            this.Note = data.notes;
            
            this.SaveType = 'Edit';
            document.getElementById('PraProjectCode').readOnly = true;

            if(view){
                $(".save-button").hide();
                this.ModalTitle = 'View Pra-Project: ';
            }

            window.$('#praProjectModal').modal('show');
        },
        async inputValidation() {
            this.Error = 0;
            $(".form-error").css("display", "none");

            if(this.PraProjectName == '' || this.PraProjectName == null){
                this.errorShow('errorPraProjectName');
            }
            if(this.PraProjectDate == '' || this.PraProjectDate == null){
                this.errorShow('errorPraProjectDate');
            }
            if(this.Sales == '' || this.Sales == null){
                this.errorShow('errorSales');
            }
            if(this.Area == '' || this.Area == null){
                this.errorShow('errorArea');
            }
        },
        saveClick() {
            this.inputValidation();

            if(this.Error == 0){
                const preProjectData = {
                    pre_project_code: this.PraProjectCode,
                    pre_project_date: this.PraProjectDate,
                    pre_project_name: this.PraProjectName,
                    status: this.Status,
                    sales_id: this.Sales.value,
                    areacode: this.Area.value,
                    notes: this.Note
                };

                if(this.SaveType == 'Add'){
                    this.$loading(true);

                    const variables = {
                        data : preProjectData
                    };

                    praProjectService.addQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#praProjectModal').modal('hide');
                        this.$swal("Info", response.successSave, "success");
                        this.$props.reload();
                    }).catch(e => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    });
                }
                else if(this.SaveType == 'Edit'){
                    this.$loading(true);

                    const variables = {
                        id : this.Id,
                        data : preProjectData
                    };
                    
                    praProjectService.editQuery(variables).then(res => {
                        this.$loading(false);
                        window.$('#praProjectModal').modal('hide');
                        this.$swal("Info", response.successUpdate, "success");
                        this.$props.reload();
                    }).catch(e => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.ErrorExtractor(e)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            }
        },
        errorShow(errorCode){
            document.getElementById(errorCode).style.display = 'block';
            document.getElementById(errorCode).innerHTML = response.fieldRequired;
            if(this.Error <= this.$globalvariable.max_error_focus){ document.getElementById(errorCode.replace("error", "")).focus(); }
            this.Error++;
        },
    },
}
</script>

<style scoped>
.col-sm-label, .col-sm-content, .col-sm-half {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
@media (min-width: 768px) {
    .col-sm-label, .col-sm-content, .col-sm-half {
        float: left;
    }
    .col-sm-label {
        width: 12%;
    }
    .col-sm-content {
        width: 21%;
    }
    .col-sm-half{
        width: 33%;
    }
    .input-number{
        text-align: right;
    }
}
>>> {
    --vs-controls-size: 0.7;
    --vs-actions-padding: 4px 2px 0 3px;
    --vs-border-color: #d8dbe0;
    --vs-selected-color: #768192;
    --vs-dropdown-option-color: #768192;
    --vs-selected-max-height: 10px;
}
.label-file {
  width : 100%;
  overflow:hidden;
  display:inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>