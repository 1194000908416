import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class PraProjectService {
    readQuery(){
        var query = `
            query($paging:ServerPaging, $status:[String]){
                GetTransactionPreProject(Paging:$paging, Status:$status){
                    pre_project {
                        created_at
                        last_update
                        pre_project_id
                        pre_project_code
                        pre_project_date
                        pre_project_name
                        status
                        sales_id
                        sales_name
                        areacode
                        areaname
                        notes
                        valuation
                        recommended_percentage
                        recommended_amount
                        commission_amount
                        is_approved
                    }
                    total
                }
            }`;
        return query;
    }

    async getDetailPraProject(id){
        const variables = {
            id : id
        }
        var query = gql`query ( $id:Int ) {
            GetTransactionPreProject( PreProjectId:$id ){
                pre_project {
                    created_at
                    last_update
                    pre_project_id
                    pre_project_code
                    pre_project_date
                    pre_project_name
                    status
                    sales_id
                    sales_name
                    areacode
                    areaname
                    notes
                    valuation
                    recommended_percentage
                    recommended_amount
                    commission_amount
                    is_approved
                }
                total
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionPreProject.pre_project[0];
    }

    async deleteQuery(variables){
        var query = gql`
        mutation($id: Int! ){
            DeleteTransactionPreProject(PreProjectID: $id )
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async addQuery(variables){
        var query = gql`
        mutation($data: NewTransactionPreProject!){
            CreateTransactionPreProject(New: $data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
        mutation($id: Int!, $data: NewTransactionPreProject! ){
            UpdateTransactionPreProject(PreProjectID: $id,  New: $data )
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }

    async getSalesQuery(){
        var query = gql`query{
            GetContact(ContactType: "Employee"){
                contact_id
                contact_code
                contact_name
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var salesData = [];
        for (let i = 0; i < result.data.GetContact.length; i++) {
            var str = { value: result.data.GetContact[i].contact_id,
                        label: result.data.GetContact[i].contact_code + ' (' + result.data.GetContact[i].contact_name + ')'}
            salesData.push(str);
        }
        return salesData
    }

    async getAreaQuery(){
        var query = gql`query{
            GetAreaCode{
                areacode
                areaname
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var data = result.data.GetAreaCode;
        var areaData = [];
        if(data != null){
            for (let i = 0; i < data.length; i++) {
                var str = { value: data[i].areacode, 
                            label: data[i].areacode + ' (' + data[i].areaname + ')'}
                areaData.push(str);
            }
        }
        return areaData
    }
}


export default new PraProjectService();