import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';
import moment from 'moment';

class PraProjectApproverService {
    readQuery(){
        var query = `query($paging:ServerPaging, $approval:Boolean){
            GetTransactionPreProject(Paging:$paging, forApproval:$approval){
                    pre_project {
                        created_at
                        last_update
                        pre_project_id
                        pre_project_code
                        pre_project_date
                        pre_project_name
                        status
                        sales_id
                        sales_name
                        areacode
                        areaname
                        notes
                        valuation
                        recommended_percentage
                        recommended_amount
                        commission_amount
                        is_approved
                    }
                    total
                }
            }`;
        return query;
    }
    
    async getDetailPraProject(id){
        const variables = {
            id : id
        }
        var query = gql`query ( $id:Int ) {
            GetTransactionPreProject( PreProjectId:$id ){
                pre_project {
                    created_at
                    last_update
                    pre_project_id
                    pre_project_code
                    pre_project_date
                    pre_project_name
                    status
                    sales_id
                    sales_name
                    areacode
                    areaname
                    notes
                    valuation
                    recommended_percentage
                    recommended_amount
                    commission_amount
                    is_approved
                }
                total
            }
        }`;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetTransactionPreProject.pre_project[0];
    }

    async approveQuery(variables){
        var query = gql`
            mutation($id: Int!, $data: NewTransactionPreProject! ){
                UpdateTransactionPreProject(PreProjectID: $id,  New: $data )
            }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new PraProjectApproverService();