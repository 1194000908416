<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>PRM</h2>
            </CCardHeader>
            <CCardBody>
                <prm-grid/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import prmGrid from './Grid/PRMGrid.vue';
import prmServices from './Script/PRMServices.js';

export default {
    name: 'PRM',
    components: {
        'prm-grid': prmGrid
    },
    data(){
        return{
        }
    },
    methods: {
    }
}
</script>

<style scoped>
</style>